import React from 'react';
import HeroStyle5 from '../Hero/HeroStyle5';
import Cta from '../Cta';
import CtaStyle2 from '../Cta/CtaStyle2';

import Spacing from '../Spacing';
import SectionHeading from '../SectionHeading';

import PricingTableList from '../PricingTable/PricingTableList';

import IconboxStyle4 from '../IconBox/IconboxStyle4';
import Card from '../Card';
import CardStyle2 from '../Card/CardStyle2';
import SectionHeadingStyle2 from '../SectionHeading/SectionHeadingStyle2';
import IconBoxStyle5 from '../IconBox/IconBoxStyle5';
import BrandCarousel from '../Slider/BrandCarousel';
import { pageTitle } from '../../helpers/PageTitle';
const brandData = [
  { logoSrc: '/images/tech-startup/brand_1.svg', alt: 'Brand' },
  { logoSrc: '/images/tech-startup/brand_2.svg', alt: 'Brand' },
  { logoSrc: '/images/tech-startup/brand_3.svg', alt: 'Brand' },
  { logoSrc: '/images/tech-startup/brand_4.svg', alt: 'Brand' },
  { logoSrc: '/images/tech-startup/brand_5.svg', alt: 'Brand' },
  { logoSrc: '/images/tech-startup/brand_6.svg', alt: 'Brand' },
  { logoSrc: '/images/tech-startup/brand_1.svg', alt: 'Brand' },
  { logoSrc: '/images/tech-startup/brand_2.svg', alt: 'Brand' },
  { logoSrc: '/images/tech-startup/brand_3.svg', alt: 'Brand' },
  { logoSrc: '/images/tech-startup/brand_4.svg', alt: 'Brand' },
  { logoSrc: '/images/tech-startup/brand_5.svg', alt: 'Brand' },
  { logoSrc: '/images/tech-startup/brand_6.svg', alt: 'Brand' },
];
const funfactData = [
  { title: 'Happy Customers', number: '22k' },
  { title: 'Work’s Completed', number: '15k' },
  { title: 'Skilled Team Members', number: '121' },
  { title: 'Most Valuable Awards', number: '15' },
];
const testimonialData = [
  {
    text: 'Zivans Motion Graphics did an excellent job on my video related projects. The motion graphics added an extra layer of polish and really brought the video to life. I highly recommend their high quality services and work.',
    avatarName: 'Ansari Patron',
    avatarDesignation: 'CEO at Delta',
  },
  {
    text: 'Zivans Motion Graphics did an excellent job on my video related projects. The motion graphics added an extra layer of polish and really brought the video to life. I highly recommend their high quality services and work.',
    avatarName: 'Jhon Doe',
    avatarDesignation: 'Manager at Delta',
  },
  {
    text: 'Zivans Motion Graphics did an excellent job on my video related projects. The motion graphics added an extra layer of polish and really brought the video to life. I highly recommend their high quality services and work.',
    avatarName: 'Ramatam Coo',
    avatarDesignation: 'MD at Delta',
  },
];
const postData = [
  {
    thumbnailSrc: '/images/creative-agency/post_1.jpeg',
    title: 'How to keep fear from ruining your art business with confident',
    date: '07 Mar 2023',
    url: '/blog/blog-details',
  },
  {
    thumbnailSrc: '/images/creative-agency/post_2.jpeg',
    title: 'Artistic mind will be great for creation anything',
    date: '22 Apr 2023',
    url: '/blog/blog-details',
  },
  {
    thumbnailSrc: '/images/creative-agency/post_3.jpeg',
    title: 'AI will take over all job for human within few years',
    date: '13 May 2023',
    url: '/blog/blog-details',
  },
  {
    thumbnailSrc: '/images/creative-agency/post_4.jpeg',
    title: 'Your agency need to replace some artistic mind people',
    date: '15 Mar 2023',
    url: '/blog/blog-details',
  },
  {
    thumbnailSrc: '/images/creative-agency/post_1.jpeg',
    title: 'How to keep fear from ruining your art business with confident',
    date: '07 Mar 2023',
    url: '/blog/blog-details',
  },
  {
    thumbnailSrc: '/images/creative-agency/post_2.jpeg',
    title: 'Artistic mind will be great for creation anything',
    date: '22 Apr 2023',
    url: '/blog/blog-details',
  },
  {
    thumbnailSrc: '/images/creative-agency/post_3.jpeg',
    title: 'AI will take over all job for human within few years',
    date: '13 May 2023',
    url: '/blog/blog-details',
  },
  {
    thumbnailSrc: '/images/creative-agency/post_4.jpeg',
    title: 'Your agency need to replace some artistic mind people',
    date: '15 Mar 2023',
    url: '/blog/blog-details',
  },
];

export default function TechStartupPage() {
  pageTitle('Fifth Dimension : Empowering Businesses');
  return (
    <>
      <HeroStyle5
        highlightTitle="Unleash your power with a powerful website"
        title="Fifth Dimension empowering businesses"
        subTitle="Transforming Web Realms: Explore the Fifth Dimension."
        btnText="See All Project"
        btnUrl="/portfolio"
        videoSrc="https://www.youtube.com/embed/NN7YEIlc-Oc?si=8YykBDKNzsOtKobC"
        videoBtnText="Play Video"
      />
      <div className="cs_primary_bg">
        <Spacing lg="95" md="60" />
        <div className="container">
          <BrandCarousel data={brandData} />
        </div>
        <Spacing lg="95" md="60" />
      </div>
      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="Unlock the Power of the Fifth Dimension: <br /> Our Signature Featuress"
            subTitle="Features"
            variant="cs_type_1"
          />
          <Spacing lg="85" md="45" />
          <div className="row g-3">
            <div className="col-lg-6">
              <IconboxStyle4
                iconSrc="/images/tech-startup/feature_icon_1.svg"
                title="Dynamic Design Mastery"
                subTitle="Craft captivating, responsive designs for diverse devices."
                btnText="Learn More"
                btnUrl="/service-details"
              />
            </div>
            <div className="col-lg-6">
              <IconboxStyle4
                iconSrc="/images/tech-startup/feature_icon_2.svg"
                title="Seamless Scalability Solutions"
                subTitle="Expand effortlessly with our robust infrastructure.."
                btnText="Learn More"
                btnUrl="/service-details"
              />
            </div>
            <div className="col-lg-6">
              <IconboxStyle4
                iconSrc="/images/tech-startup/feature_icon_3.svg"
                title="Intuitive User Experience "
                subTitle="Delight with streamlined navigationaland personalized interfaces.."
                btnText="Learn More"
                btnUrl="/service-details"
              />
            </div>
            <div className="col-lg-6">
              <IconboxStyle4
                iconSrc="/images/tech-startup/feature_icon_4.svg"
                title="Exceptional Customer Service"
                subTitle="Experience unparalleled support and guidance from our dedicated team."
                btnText="Learn More"
                btnUrl="/service-details"
              />
            </div>
          </div>
        </div>
        <Spacing lg="150" md="80" />
      </section>
      <section className="cs_primary_bg">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeadingStyle2
            title="Imaginative solutions that drive <br />creativity & innovation."
            subTitle="Features"
            btnText="Learn More"
            btnUrl="/about"
          />
          <Spacing lg="85" md="45" />
          <div className="row g-3">
            <div className="col-lg-12">
              <Card
                thumbnailSrc="/images/tech-startup/goal.png"
                title="Revolutionize industries with cutting-edge solutions."
                miniTitle="Our Goal"
              />
            </div>
            <div className="col-lg-6">
              <CardStyle2
                miniTitle="Our Mission"
                title="Empowering businesses through tech innovation."
                subTitle="To empower businesses with innovative digital solutions that transcend conventional boundaries, fostering growth, connectivity, and success in the ever-evolving online landscape."
                variant="cs_mission"
              />
            </div>
            <div className="col-lg-6">
              <CardStyle2
                miniTitle="Our Vision"
                title="Transforming businesses, shaping a smarter world."
                subTitle=" To pioneer a digital revolution where creativity knows no bounds, inspiring limitless possibilities and reshaping the future of online experiences worldwide."
                variant="cs_vision"
              />
            </div>
          </div>
        </div>
        <Spacing lg="150" md="80" />
      </section>
      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="Our aim is to improve tech based <br /> solution on business"
            subTitle="Services"
            variant="cs_type_1"
          />
          <Spacing lg="85" md="45" />
          <div className="row g-3">
            <div className="col-lg-6 col-xl-3">
              <IconBoxStyle5
                iconSrc="/images/tech-startup/service_icon_1.svg"
                title="Domain Name"
                subTitle="Secure your online identity effortlessly with our streamlined domain registration service, ensuring your brand stands out in the digital realm."
                href="/service-details"
              />
            </div>
            <div className="col-lg-6 col-xl-3">
              <IconBoxStyle5
                iconSrc="/images/tech-startup/service_icon_2.svg"
                title="Professional Email "
                subTitle="Enhance your professional image with custom email addresses tailored to your domain, ensuring credibility and trustworthiness in your communications."
                href="/service-details"
              />
            </div>
            <div className="col-lg-6 col-xl-3">
              <IconBoxStyle5
                iconSrc="/images/tech-startup/service_icon_3.svg"
                title="SEO Optimization"
                subTitle="Boost your website's visibility and rank higher on search engine results pages with our proven SEO strategies, driving organic traffic."
                href="/service-details"
              />
            </div>
            <div className="col-lg-6 col-xl-3">
              <IconBoxStyle5
                iconSrc="/images/tech-startup/service_icon_4.svg"
                title="Web Solutions"
                subTitle="From concept to launch, we provide end-to-end website development services, including design, development, content creation."
                href="/service-details"
              />
            </div>
          </div>
        </div>
      </section>
      {/* <div className="container">
        <Spacing lg="130" md="70" />
        <VideoModal
          videoSrc="https://www.youtube.com/embed/VcaAVWtP48A"
          bgUrl="/images/tech-startup/hero_video_bg.jpeg"
          title="Our Design and Technology <br /> Studio is dedicated to making <br />your ideas a reality"
          titleVariant="cs_outline_text"
        />
      </div> */}
      
    
      
      
      <section className="cs_shape_animation_2">
        
        
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="Offering budget friendly pricing <br /> solutions for your business"
            subTitle="Our Pricing"
            variant="cs_type_1"
          />
          <Spacing lg="85" md="45" />
        </div>
        <div className="container">
          <PricingTableList />
        </div>
        <div className="cs_height_150 cs_height_lg_80" />
      </section>
      {/* <section className="cs_p76_full_width cs_gray_bg_2">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="Exploring the world of innovation <br />in our recent news"
            subTitle="Our Blog"
            variant="cs_type_1"
          />
          <Spacing lg="85" md="45" />
        </div>
        <PostCarousel data={postData} />
        <Spacing lg="150" md="80" />
      </section> */}
      <section>
      <Spacing lg="150" md="80" />
      <CtaStyle2
        title="Worried about long term updation and support? "
        btnText="Talk to us "
        btnUrl="/contact"
      />
      </section>
      <section>
        <Spacing lg="150" md="80" />
        <div className="container">
          <Cta
            title="Is there a specific project or goal that you have in mind?"
            btnText="Contact Us"
            btnUrl="/contact"
            bgUrl="/images/tech-startup/cta_bg.jpeg"
            noShape
          />
        </div>
        <Spacing lg="150" md="80" />
      </section>
    </>
  );
}
