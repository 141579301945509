import React from 'react';
import { useState } from 'react';
import PricingTable from '.';

export default function PricingTableList() {
  const [tab, setTab] = useState('monthly');
  return (
    <div className="cs_tabs cs_style1">
      <ul className="cs_tab_links cs_style1 cs_mp0 cs_pricing_control">
        <li
          className={tab === 'monthly' ? 'active' : ''}
          onClick={() => setTab('monthly')}
        >
          <span>Without Domain</span>
          <span className="cs_switch"></span>
        </li>
        <li
          className={tab === 'yearly' ? 'active' : ''}
          onClick={() => setTab('yearly')}
        >
          <span>With Domain</span>
        </li>
      </ul>
      <div className="row cs_gap_y_30">
        <div className="col-lg-4">
          {tab === 'monthly' && (
            <PricingTable
              title="Silver"
              subTitle="Control your choices for effective business growth and more customers."
              price="8000"
              currency="₹"
              timeline="one time"
              features={[
                '5 web pages ',
                '6 months free updation',
                'Contact Page',
                'Fully responsive site',
              ]}
              btnText="Choose Package"
              btnLink="/"
            />
          )}
          {tab === 'yearly' && (
            <PricingTable
              title="Silver"
              subTitle="Control your choices for effective business growth and more customers."
              price="10000"
              currency="₹"
              timeline="One Time"
              features={[
                '3 years Domain + Hosting ',
                '1 Year Free support & Updation',
                '10 Professional Email receiver\'s address',
                'Google Listing',
                'Fully Responsive 5 web pages + Contact page'
              ]}
              btnText="Choose Packedge"
              btnLink="/"
            />
          )}
        </div>
        <div className="col-lg-4">
          {tab === 'monthly' && (
            <PricingTable
              title="Gold"
              subTitle="Control your choices for effective business growth and more customers."
              price="10000"
              currency="₹"
              timeline="One Time"
              features={[
                '8 web pages ',
                '1 Year free updation',
                'Contact Page + Specialized email setup',
                'Fully responsive site',
                'Video Backgroud',
                'Google search Listing'
              ]}
              btnText="Choose Packedge"
              btnLink="/"
              popular
            />
          )}
          {tab === 'yearly' && (
            <PricingTable
              title="Gold"
              subTitle="Control your choices for effective business growth and more customers."
              price="13000"
              currency="₹"
              timeline="One Time"
              features={[
                '3 years Domain + Hosting ',
                '1 Year Free support & Updation',
                '15 Professional Email receiver\'s address',
                'Google Listing + SEO',
                'Fully Responsive 8 web pages + Contact page',
                'Marketing',
                '4 Professional Email Sender\'s address'
              ]}
              btnText="Choose Packedge"
              btnLink="/"
              popular
            />
          )}
        </div>
        <div className="col-lg-4">
          {tab === 'monthly' && (
            <PricingTable
              title="Platinum"
              subTitle="Control your choices for effective business growth and more customers."
              price="12000"
              currency="₹"
              timeline="One Time"
              features={[
                '8-10 web pages ',
                '1 Year free updation',
                'Contact Page + Specialized email setup',
                'Fully responsive site',
                'Video Backgroud',
                'Google search Listing',
                'Google Business Setup',
                'Professional Email Template '
              ]}
              btnText="Choose Packedge"
              btnLink="/"
            />
          )}
          {tab === 'yearly' && (
            <PricingTable
              title="Platinum"
              subTitle="Control your choices for effective business growth and more customers."
              price="15000"
              currency="₹"
              timeline="One Time"
              features={[
                '3 years Domain + Hosting ',
                '1 Year Free support & Updation',
                '∞ Professional Email receiver\'s address',
                'Google Listing + SEO',
                'Fully Responsive 10 web pages + Contact page',
                'Marketing + Google Business Setup',
                '6 Professional Email Sender\'s address',
                'Professional Email Template '
              ]}
              btnText="Choose Packedge"
              btnLink="/"
            />
          )}
        </div>
      </div>
    </div>
  );
}
